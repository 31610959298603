<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorder' }">{{$t('message.myorder')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorderinfo',query:{order_no:orderCode} }">{{$t('message.order')}}：{{orderCode}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.commentxt')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【商品评价】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap" v-for="(item,index) in orderData" :key="index">
                <div class="pc-shop">
                    <div class="pc-shop-group">
                        <div class="pc-shop-img"><img :src="item.goods_img" alt=""></div>
                        <div class="pc-shop-info">
                            <div class="pc-shop-title">
                                {{languageName=='cn'?item.cn_name:''}}
                                {{languageName=='en'?item.en_name:''}}
                                {{languageName=='it'?item.it_name:''}}
                            </div>
                            <div class="pc-shop-numer">
                                {{languageName=='cn'?item.cn_spect_name:''}}
                                {{languageName=='en'?item.en_spect_name:''}}
                                {{languageName=='it'?item.it_spect_name:''}}
                                <span class="pc-num">&times;{{item.goods_num}}</span></div>
                            <div class="pc-shop-money">{{$t('message.m')}}{{item.price}}</div>
                        </div>
                    </div>
                </div>
                <div class="pc-all">
                    <div class="pc-commenttitle">
                        <span class="pc-title">{{$t('message.shopcomment')}}</span>
                        <my-rate :score.sync="item.rateNum" :disabled="false"/>
                    </div>
                    <div class="pc-input"><el-input type="textarea" v-model="item.content" :placeholder="'*'+$t('message.writehert')+'~'"></el-input></div>
                </div>
            </div>
            <div class="pc-link">
                <span class="pc-linkbtn" @click="getWvaluate">{{$t('message.subcommont')}}</span>
            </div>
        </b-container>
        <!-- wap布局【商品评价】 -->
        <b-container class="wap-shopcar">
            <b-row v-for="(item,index) in orderData" :key="index">
                <div class="wap-shop-group">
                    <div class="wap-shop-img"><img :src="item.goods_img" alt=""></div>
                    <div class="wap-shop-info">
                        <div class="wap-shop-title">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                            </div>
                        <div class="wap-shop-numer">
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}
                        </div>
                        <div class="wap-shop-money">{{$t('message.m')}}{{item.price}}</div>
                    </div>
                    <div class="wap-shop-num">&times;{{item.goods_num}}</div>
                </div>
                <div class="wap-commenttitle">
                    <span class="wap-title">{{$t('message.shopcomment')}}</span>
                    <my-rate :score.sync="item.rateNum" :disabled="false"/>
                </div>
                <div class="wap-input"><el-input type="textarea" class="wap-areatext" v-model="item.content" :placeholder="'*'+$t('message.writehert')+'~'"></el-input></div>
            </b-row>
            <div class="wap-link">
                <span class="wap-linkbtn" @click="getWvaluate">{{$t('message.subcommont')}}</span>
            </div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
import myRate from '@/components/commons/rates.vue';
export default {
    name:'shopcarinfo',
    data() {
        return {
            token:'',
            languageName:'',
            languageObj:{},
            orderCode:'',
            orderData:[],
        }
    },
    components:{
        myRate
    },
    methods: {
        //获取订单详情
        getOrderInfo(token,orderno){
            this.$http.api_order_getorderinfo({
                token:token,
                orderno:orderno
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            res.data.goods.map((v)=>{
                                v.rateNum = 0;
                                v.content = '';
                            });
                            this.orderData = res.data.goods;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        },
        // 提交评价
        getWvaluate(){
            if(this.token){
                let arrs = [];
                this.orderData.map((v)=>{
                    let data = {
                        ogid:v.og_id,
                        score:v.rateNum,
                        content:v.content,
                    }
                    arrs.push(data);
                });
                this.$http.api_order_addfeedback({ 
                    token:this.token, 
                    orderno:this.orderCode, 
                    datalist:arrs
                } ).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                ElementUI.Message({
                                    message:res.message,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                                setTimeout(()=>{
                                    this.$router.push({path:'/myorderinfo',query: {order_no: this.orderCode}})
                                },1000)
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
            
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            let routes = this.$route.query;
            if(routes&&routes.order_no){
                this.orderCode = routes.order_no;
                this.getOrderInfo(token,routes.order_no);//获取订单详情
            }
        }else{
            // ElementUI.Message({
            //     message:this.languageObj.logintip,
            //     type:'error',
            //     center: true,
            //     offset:10,
            // });
            // this.$store.commit('setLoginStatus',true);
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.navtab{
    padding:20px 10px 10px;
    
}
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        background: #f4f7fe;
        margin-bottom: 20px;
        .pc-shop{
            width: 40%;
            padding:20px 20px 40px;
            .pc-shop-group{
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                padding:10px 0;
                .pc-shop-img{
                    width:20%;
                    img{
                        width: 100%;
                    }
                }
                .pc-shop-info{
                    width:80%;
                    padding-left:20px;
                    .pc-shop-title{
                        
                        line-height: 30px;
                    }
                    .pc-shop-numer{
                        color: #a4a4ad;
                        .pc-num{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                    .pc-shop-money{
                        margin-top:20px;
                        color: #bf0000;
                    }
                }
            }
        }
        .pc-all{
            width: 60%;
            padding:20px 60px 40px;
            .pc-commenttitle{
                display: flex;
                -webkit-box-orient: horizontal;
                vertical-align: middle;
                color: #95959E;
                .pc-title{
                    color: #95959E;
                    margin-right: 10px;
                }
                img{
                    display: inline-block;
                    width: 24px;
                    vertical-align: top;
                }
            }
            .pc-input{
                width: 100%;
                border:1px solid #eee;
                margin-top: 20px;
                border-radius:5px;
                input{
                    height: 100%;
                    width: 100%;
                    border: none;
                    outline: none;
                    padding:0 20px;
                    background: transparent;
                }
                /deep/.el-textarea__inner{
                    height:130px;
                    resize: none;
                }
            }
            
        }
    }
    .pc-link{
        height: 40px;
        width: 100%;
        text-align: right;
        margin-top: 20px;
        .pc-linkbtn{
            display: inline-block;
            padding:10px 20px;
            border-radius:4px;
            background: #222;
            color: #fff;
            
            cursor: pointer;
        }
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shopcar{display: none;}
        .pc-shopcar .pc-shopwrap .pc-all .pc-commenttitle img{width: 20px;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            background: #fff;   
            padding: 0 0 20px;
            .row{
                padding: 0;
                margin: 0 0 20px; 
                background: #f4f7fe;
                .wap-shop-group{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:10px 0;
                    .wap-shop-img{
                        display: flex;
                        width:30%;
                        padding-left: 10px;
                        img{
                            width: 100%;
                        }
                    }
                    .wap-shop-info{
                        width:60%;
                        padding-left:20px;
                        .wap-shop-title{
                            
                            line-height: 30px;
                        }
                        .wap-shop-numer{
                            color: #a4a4ad;
                        }
                        .wap-shop-money{
                            margin-top:20px;
                        }
                    }
                    .wap-shop-num{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width:10%;
                        padding-right: 10px;
                        color: #a4a4ad;
                    }
                }
                .wap-commenttitle{
                    width: 100%;
                    color: #95959E;
                    padding:10px 10px 0;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-title{
                        margin-right: 10px;
                    }
                    img{
                        display: inline-block;
                        width: 20px;
                        vertical-align: top;
                    }
                }
                .wap-input{
                    width: 100%;
                    padding:10px;
                    .wap-areatext{
                        height:130px;
                        resize: none;
                        /deep/.el-textarea__inner{
                            height: 100%;resize: none;
                        }
                    }
                }
            }
            .wap-link{
                height: 40px;
                width: 100%;
                padding:0 10px;
                .wap-linkbtn{
                    display: inline-block;
                    width: 100%;
                    padding:10px 20px;
                    border-radius:4px;
                    text-align: center;
                    background: #222;
                    color: #fff;
                    
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            background: #fff;   
            padding: 0 0 20px;
            .row{
                padding: 0;
                margin: 0 0 20px; 
                background: #f4f7fe;
                .wap-shop-group{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:10px 0;
                    .wap-shop-img{
                        display: flex;
                        width:30%;
                        padding-left: 10px;
                        img{
                            width: 100%;
                        }
                    }
                    .wap-shop-info{
                        width:60%;
                        padding-left:20px;
                        .wap-shop-title{
                            
                            line-height: 30px;
                        }
                        .wap-shop-numer{
                            color: #a4a4ad;
                        }
                        .wap-shop-money{
                            margin-top:20px;
                        }
                    }
                    .wap-shop-num{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width:10%;
                        padding-right: 10px;
                        color: #a4a4ad;
                    }
                }
                .wap-commenttitle{
                    width: 100%;
                    color: #95959E;
                    padding:10px 10px 0;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-title{
                        margin-right: 10px;
                    }
                    img{
                        display: inline-block;
                        width: 20px;
                        vertical-align: top;
                    }
                }
                .wap-input{
                    width: 100%;
                    padding:10px;
                    .wap-areatext{
                        height:130px;
                        resize: none;
                        /deep/.el-textarea__inner{
                            height: 100%;resize: none;
                        }
                    }
                }
            }
            .wap-link{
                height: 40px;
                width: 100%;
                padding:0 10px;
                .wap-linkbtn{
                    display: inline-block;
                    width: 100%;
                    padding:10px 20px;
                    border-radius:4px;
                    text-align: center;
                    background: #222;
                    color: #fff;
                    
                    cursor: pointer;
                }
            }
        }
        
    }
}
</style>